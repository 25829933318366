$blue: #4ca9d4;

@mixin screen-lg {
    @media only screen and (max-width: 1200px) {
        @content;
    }
}


@mixin screen-md {
    @media only screen and (max-width: 992px) {
        @content;
    }
}

@mixin screen-sm {
    @media only screen and (max-width: 768px) {
        @content;
    }
}

@mixin screen-xs {
    @media only screen and (max-width: 767px) {
        @content;
    }
}

%clearfix {
    *zoom: 1;
    &:before,
    &:after {
        display: table;
        content: "";
        // Fixes Opera/contenteditable bug:
        // http://nicolasgallagher.com/micro-clearfix-hack/#comment-36952
        line-height: 0;
    }
    &:after {
        clear: both;
    }
}

/* Sticky footer styles
-------------------------------------------------- */
html {
	position: relative;
	min-height: 100%;
}
body {
	margin-bottom: 200px;
	font-family: 'Open Sans', sans-serif;
}
.footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 200px;
	
	color: #fff;
	text-align: center;
	font-size: 12px;
	p {
		margin: 18px 0 10px;
	}
	.container {
		background-color: #3d9ece;
		padding-bottom: 15px;
		max-width: 100%;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		&.narrow {
			background: #fff;
			color: #a2a2a2;
			text-align: left;
			font-size: 15px;
			border-top: solid 1px #e5e5e5;
			padding: 26px 0;
			top: 0;
			bottom: auto;
			.phone {
				font-size: 16px;
				color: #000;
				font-weight: bold;
			}
		}
	}
}

// ==============================================

.container {
	width: 100% !important;
	max-width: 1400px;
	
	&.narrow {
		max-width: 900px;
	}
}

h1 {
	font-weight: bold;
	font-size: 26px;
	margin: 10px 0 30px;
	
	.btn {
		float: right;
	}
}

.btn {
	border: none;
	border-radius: 32px;
	padding-left: 25px;
	padding-right: 25px;
	transition: all .2s ease-in-out;
	&:hover {
		transform: scale(1.06);
		transition: all .2s ease-in-out;
		box-shadow: 0 0 9px rgba(0,0,0, .2);
	}
}

.btn-success {
	background: #54ae53;
	&:hover {
		background: #62d135;
	}
}

.btn-info {
	background: #4ca9d4;
	&:hover {
		background: #40aef8;
	}
}

.header {
	background: $blue;
	.navbar {
		min-height: 90px;
		color: #fff;
	}
	.navbar-header {
		height: 90px;
	}
	.navbar-collapse {
		padding-left: 20px;
		padding-right: 20px;
	}
	
	.navbar-default {
		background-color: transparent;
	}
	
	.navbar-brand {
		padding: 0 15px;
		margin-top: 23px;
		height: auto;
	}
	.navbar-toggle {
		top: 20px;
	}
	.navbar-default .navbar-toggle .icon-bar {
		background: #fff;
	}
	.navbar-nav {
		margin-top: 25px;
		
		@include screen-xs {
			margin-bottom: 25px;
		}
	}
	
	.your-day-limit {
		display: inline-block;
		height: 40px;
		line-height: 40px;
		padding-left: 20px;
		padding-right: 20px;
	}
	.navbar-default .navbar-nav>li {
		height: 40px;
		line-height: 40px;
		&.exit {
			padding-left: 15px;
			a {
				line-height: 1em;
				height: auto;
				display: inline-block;
				margin: 0 0 0 6px;
				padding: 0;
			}
		}
	}
	.navbar-default .navbar-nav>li>a {
		color: #fff;
		padding: 0;
		height: 40px;
		line-height: 40px;
		border-radius: 40px;
		padding: 0 20px;
		font-size: 14px;
		
		&:hover {
			text-decoration: underline;
		}
		
		.fa {
			margin-right: 10px;
			font-size: 16px;
		}
	}
	.navbar-default .navbar-nav>.active>a, 
	.navbar-default .navbar-nav>.active>a:focus, 
	.navbar-default .navbar-nav>.active>a:hover {
		background: #fff;
		color: $blue;
		text-decoration: none;
	}
}

.suppliers {
	.nav-tabs {
		border: none;
		display: flex;
		align-items: flex-start;
		text-align: center;
		justify-content: space-between;
	}
	.nav-tabs>li {
		//border-left: solid 1px #eee;
		text-align: center;
		padding-left: 30px;
		padding-right: 30px;
		float: none;
		&:first-child {
			padding-left: 0;
			border-left: none;
		}
	}
	.nav-tabs>li>a {
		padding: 0;
		font-size: 16px;
		font-weight: 500;
		color: #c1c1c1;
		border: none;
		&:hover {
			color: #3b3b3b;
			background: none;
		}
	}
	.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
		border: none;
		color: #3b3b3b;
	}
	
	&-content {
		.container {
			position: relative;
			//padding-right: 25%;
			padding-top: 20px;
			padding-bottom: 30px;
			//min-height: 600px;
			
			overflow: hidden;
			
			@include screen-lg {
				//padding-right: 33%;
			}
			
			@include screen-sm {
				padding-right: 0;
			}
		}
		
		.tab-content {
			width: 75%;
			
			@include screen-md {
				width: 60%;
			}
			
			@include screen-sm {
				width: 100%;
			}
		}
		
		.cart {
			/*
position: absolute;
			right: 15px;
			top: 20px;
*/
			float: right;
			width: 25%;
			padding: 20px;
			border-radius: 20px;
			border: solid 1px #f2f2f2;
			box-shadow: 0 0 20px rgba(0,0,0, 0.1);
			overflow: hidden;
			
			@include screen-md {
				width: 40%;
			}
			
			@include screen-sm {
				width: auto;
				margin-right: 15px;
				float: none;
				margin-bottom: 20px;
			}
			
			h4 {
				text-align: center;
				margin: 10px  0 20px;
				font-weight: bold;
				font-size: 25px;
			}
			
			&__item, &__title {
				display: flex;
				border-bottom: dashed 1px #e6e6e6;
				align-items: flex-end;
				justify-content: flex-start;
				padding: 16px 0;
				div {
					width: 20%;
					text-align: center;
					&:first-child {
						width: 60%;
						text-align: left;
						padding-right: 15px;
					}
					&:last-child {
						width: 20%;
						text-align: right;
						span {
							display: block;
						}
					} 
				}
			}
			
			&__title {
				font-weight: 700;
				color: #cbcbcb;
			}
			
			&__delete {
				display: inline-block;
				width: 16px;
				height: 16px;
				text-align: center;
				line-height: 12px;
				font-size: 16px;
				border: solid 2px #a72523;
				color: #a72523;
				border-radius: 100%;
				text-decoration: none !important;
				&:hover {
					color: #ed3833;
					border-color: #ed3833;
				}
			}
			
			&__total {
				@extend %clearfix;
				font-weight: bold;
				padding: 20px 0 14px;
				margin-top: -1px;
				border-top: solid 1px #e6e6e6;
				border-bottom: solid 1px #e6e6e6;
				margin-bottom: 20px;
				span {
					float: right;
					font-size: 20px;
					font-weight: normal;
					margin-top: -5px;
				}
			}
			
			&__limit {
				padding: 5px 0;
				margin: -20px 0 20px;
				color: #767676;
				font-size: 14px;
			}
			
			&__limit-reached {
				background: #b95252;
				margin: 20px -20px -20px;
				padding: 10px;
				text-align: center;
				color: #fff;
			}
			
			&__button {
				padding: 0 14%;
				.btn {
					//transition: all .2s ease-in-out;
					&:hover {
						//transform: scale(1.03);
						//transition: all .2s ease-in-out;
					}
					
					&[disabled] {
						background: #000;
						transform: none;
					}
				}
			}
		}
	} 
	
	.restaurants {
		background: #edf6fb;
		padding: 30px 0px;
		margin-top: -20px;
		margin-bottom: 20px;
		text-align: center;
		&__nav {
			margin: 0;
			padding: 0;
			list-style: none;
			font-size: 16px;
			font-weight: 500;
			
			li {
				display: inline-block;
				vertical-align: top;
				border-right: solid 1px #d4dde0;
				padding: 0 30px;
				text-align: center;
				
				&:first-child {
					padding-left: 0;
				}
				&:last-child {
					border: none;
					padding-right: 0;
				}
			}
			
			li.active {
				a {
					color: #4ca9d4;
					text-decoration: underline;
				}
			}
			a {
				color: #b3b9bd;
				&:hover {
					color: #4ca9d4;
					text-decoration: none;
				}
			}
		}
	}
	
}

.menu-item {
	display: inline-block;
	vertical-align: top;
	width: 33%;
	
	@include screen-lg {
		width: 49%;
	}
	
	@include screen-md {
		width: 100%;
	}
	
	&__content {
		margin: 0 30px 30px 0;
		border: solid 1px #f2f2f2;
		box-shadow: 0 0 20px rgba(0,0,0, 0.1);
		padding: 20px;
		border-radius: 20px;
		position: relative;
		
		@include screen-md {
			margin-right: 15px;
		}
		
		h4 {
			font-size: 20px;
			margin-top: 0;
			min-height: 44px;
		}
	}
	
	&__hidden {
		overflow: hidden;
		max-height: 53px;
		min-height: 53px;
		padding-bottom: 20px;
		position: relative;
		transition: all .3s ease-in-out;
		font-size: 12px;
		color: #9c9c9c;
		font-style: italic;
		margin-bottom: 10px;
		
		ul {
			padding-left: 15px;
		}
		
		&.open {
			max-height: 2000px;
			transition: all .3s ease-in-out;
		}
		
		.hidden-show {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			background: #fff;
			text-align: right;
			&:before {
				content: "...";
				position: absolute;
				left: 0;
				top: -3px;
				color: #9c9c9c;
			}
			
			.glyphicon {
				font-size: 16px;
				color: #d7d7d7;
				&:hover {
					color: #000;
				}
			}
			
			&.open {
				&:before {
					display: none;
				}
				.glyphicon {
					transform: rotate(180deg);
				}
			}
		}
	}
	
	&__info {
		@extend %clearfix;
		font-size: 16px;
		padding-top: 20px;
		border-top: dashed 1px #e5e5e5;
		span {
			float: left;
			padding-right: 15px;
			height: 32px;
			line-height: 32px;
			color: #9c9c9c;
			&.weight {
				color: #f1823b;
			}
		}
		.btn {
			float: right;
			//transition: all .2s ease-in-out;
			&:hover {
				//transform: scale(1.06);
				//transition: all .2s ease-in-out;
			}
		}
	}
}


.panel-default {
	padding: 10px;
	border: solid 1px #f2f2f2;
	box-shadow: 0 0 20px rgba(0,0,0, 0.1);
	border-radius: 20px;
	h4 {
		font-weight: bold;
		margin: 0 0 20px;
	}
}

.form-control {
	border-radius: 34px;
}


.favorites {
	.narrow {
		@extend %clearfix;
	}
	
	&__title {
		float: left;
		font-weight: bold;
		margin: 5px 0 0 !important;
		font-size: 16px;
		color: #000 !important;
	}
	
	&__days {
		float: right;
		font-size: 16px;
		font-weight: bold;
		color: #000;
		padding-top: 5px;
		span {
			display: inline-block;
			font-size: 14px;
			color: #000;
			width: 30px;
			height: 30px;
			border: solid 2px transparent;
			border-radius: 30px;
			line-height: 27px;
			text-align: center;
			text-transform: uppercase;
			font-weight: 700;
			
			&.active {
				color: #4ca9d4 !important;
				border-color: #4ca9d4;
			}
		}
		
		&.select-days {
			span {
				color: #bcc3c7;
				cursor: pointer;
				&:hover {
					color: #000;
				}
			}
		}
		
		
		.btn-group {
			margin-top: -2px;
			padding-left: 5px;
			.btn {
				font-size: 13px;
				padding: 0 !important;
				margin: 0 2px;
				background: none;
				box-shadow: none;
				transform: none;
				color: #000;
				width: 30px;
				height: 30px;
				border: solid 2px transparent;
				border-radius: 30px !important;
				line-height: 27px;
				text-align: center;
				text-transform: uppercase;
				font-weight: 700;
				color: #bcc3c7;
				cursor: pointer;
				outline: none;
				&:hover {
					color: #000;
				}
				
				&.active {
					color: #4ca9d4 !important;
					border: solid 2px #4ca9d4;
				}
				
			}
		}
		
		
	}
	
	.menu-name {
		float: left;
		width: 300px;
	}
	
	&__list {
		
	}
	
	&__item, &__titles {
		display: flex;
		border-bottom: dashed 1px #e6e6e6;
		align-items: center;
		justify-content: flex-start;
		padding: 16px 0;
		width: 100%;
		div {
			width: 20%;
			text-align: center;
			&:first-child {
				width: 60%;
				text-align: left;
				padding-right: 15px;
			}
			&:last-child {
				width: 20%;
				text-align: right;
				span {
					display: block;
				}
			} 
		}
	}
	
	&__titles {
		font-weight: 700;
		color: #cbcbcb !important;
	}
	&__buttons {
		padding: 30px 0;
		text-align: right;
	}
}


.grid-view {
	.summary {
		text-align: right;
		margin: -50px 0 30px;
	}
}


.table {
	
}

.table>tbody>tr>td, c, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
	vertical-align: middle;
	padding: 12px 8px;
	font-size: 15px;
	small{
		display: block;
		color: #858585;
		padding-top: 5px;
		padding-bottom: 5px;
	}
}

.table>thead>tr>th {
	color: #cbcbcb !important;
	border-bottom: solid 1px #e5e5e5 !important;
}

.table>tbody>tr>td {
	border-top: dashed 1px #e6e6e6;
}

.menu-table>tbody>tr>td {
	border-top: none;
	border-bottom: dashed 1px #e6e6e6;
}

.menu-table {
	.price {
		white-space: nowrap;
		padding: 0 40px;
	}
}

.login {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: #4ca9d4;
	display: flex;
	justify-content: center;
	align-items: center;
	
	&__box {
		width: 310px;
	}
	
	h3 {
		text-align: center;
		font-size: 20px;
		font-weight: 500;
		color: #fff;
		margin:  30px 0 15px;
	}
	
	input {
		height: 40px;
		margin-bottom: 15px;
	}
	.btn {
		height: 47px;
		border: solid 1px #fff;
		background: transparent !important;
		transform: none !important;
		box-shadow: none !important;
		width: 200px;
		display: block;
		margin: 30px auto;
		font-size: 18px;
	}
}

.order {
	&__item, &__titles {
		display: flex;
		border-bottom: dashed 1px #e6e6e6;
		align-items: flex-end;
		justify-content: flex-start;
		padding: 16px 0;
		width: 100%;
		
		div {
			width: 20%;
			text-align: center;
			&:first-child {
				width: 60%;
				text-align: left;
				padding-right: 15px;
			}
			&:last-child {
				width: 20%;
				text-align: right;
				span {
					display: block;
				}
			} 
		}
	}
	
	&__titles {
		font-weight: 700;
		color: #cbcbcb !important;
		border-top: dashed 1px #e6e6e6;
		border-bottom: none;
	}
	
	.alert {
		margin: 0;
	}
	
	&__total {
		@extend %clearfix;
		font-weight: bold;
		padding: 20px 0;
		font-size: 16px;
		border-top: solid 1px #e5e5e5;
		border-bottom: solid 1px #e5e5e5;
		span {
			float: right;
			font-size: 20px;
			font-weight: normal;
			margin-top: -5px;
		}
	}
	
	&__buttons {
		text-align: right;
		padding: 20px 0 50px;
	}
	
	
	.order__list {
		.order__item {
			&:last-child {
				border: none;
			}
		}
	}
}

.steps {
	text-align: right;
	margin: -55px 0 30px;
	span {
		display: inline-block;
		vertical-align: middle;
		width: 10px;
		height: 10px;
		border-radius: 100%;
		background: #dbeef6;
		margin:  0 3px;
		&.active {
			background: #4caad5;
			width: 30px;
			height: 30px;
			color: #fff;
			line-height: 30px;
			text-align: center;
			font-weight: bold;
		}
	}
}

.calendar-box {
	border: solid 1px red;
	width: 400px;
	border: solid 1px #f2f2f2;
	box-shadow: 0 0 20px rgba(0,0,0, 0.1);
	padding: 20px;
	border-radius: 20px;
}

.calendar {
	width: 100%;
	
	td, th {
		text-align: center;
		padding: 8px;
		text-transform: uppercase;
	}
	
	.month-year-row {
		border-bottom: solid 1px #d2d4d8;
		th {
			padding-bottom: 20px;
		}
	}
	.weekdays-row {
		th {
			padding: 20px 0;
		}
	}
	.btn {
		padding: 0;
		box-shadow: none !important;
		transform: none !important;
		text-decoration: none !important;
		font-weight: bold;
		color: #ccc;
		width: 30px;
		height: 30px;
		border: solid 2px transparent;
		border-radius: 100%;
		line-height: 27px;
		text-align: center;
		
		&.available-date {
			color: $blue;
		}
		&.btn-success {
			background: none;
			color: #000;
			border-color: $blue;
		}
	}
}

.new-tabs {
	border: none;
	display: flex;
	align-items: flex-start;
	text-align: center;
	justify-content: space-between;
}
.new-tabs>li {
	//border-left: solid 1px #eee;
	text-align: center;
	padding-left: 30px;
	padding-right: 30px;
	float: none;
	&:first-child {
		padding-left: 0;
		border-left: none;
	}
}
.new-tabs>li>a {
	padding: 0;
	font-size: 16px;
	font-weight: 500;
	color: #c1c1c1;
	border: none;
	&:hover {
		color: #3b3b3b;
		background: none;
	}
}
.new-tabs>li.active>a, .new-tabs>li.active>a:focus, .new-tabs>li.active>a:hover {
	border: none;
	color: #3b3b3b;
}

.numbers-row {
	width: 100px;
	padding: 0 25px;
	position: relative;
	display: inline-block;
	
	input {
		width: 100%;
		text-align: center;
		height: 35px;
		border: solid 1px #cfcfcf;
	}
	.inc {
		position: absolute;
		right: 0;
		bottom: 0;
		top: 0;
		width: 25px;
		border: solid 1px #cfcfcf;
		border-left: none;
		cursor: pointer;
		border-radius: 0px 18px 18px 0;
		text-align: center;
		line-height: 32px;
		font-size: 16px;
		background: #fff;
		&:hover {
			background: #54ae53;
			color: #fff;
		}
	}
	.dec {
		position: absolute;
		left: 0;
		bottom: 0;
		top: 0;
		width: 25px;
		border: solid 1px #cfcfcf;
		border-right: none;
		cursor: pointer;		
		border-radius: 18px 0 0 18px;
		text-align: center;
		line-height: 30px;
		font-size: 16px;
		background: #fff;
		&:hover {
			background: #b83131;
			color: #fff;
		}
		
	}
}

.meal {
	color: #54ae53;
	font-style: italic;
	display: block !important;
	font-size: 12px;
}

.new-item {
	position: relative;
	
	&.selected {
		background: #eef7ed;
		.fa {
			display: block;
		}
	}
	
	.fa {
		position: absolute;
		left: -40px;
		top: 50%;
		margin-top: -16px;
		font-size: 30px;
		font-weight: normal;
		color: #54af53;
		display: none;
	}
	
	.container {
		display: flex;
		align-items: center;
		border-bottom: dashed 1px #e6e6e6;
		max-width: 870px;
		padding: 20px 0;
		position: relative;
	}
	
	&__desc {
		width: 70%;
		padding-right: 60px;
		color: #858585;
		font-size: 12px;
		h4 {
			margin: 0 0 4px;
			font-size: 16px;
			color: #000;
		}
	}
	&__price {
		width: 10%;
	}
	&__add {
		width: 20%;
		text-align: right;
	}
}


.modal {
	text-align: center;
	padding: 0!important;
	background: rgba(76,169,212, 0.8);
	.close {
		position: absolute;
		right: 8px;
		top: 5px;
		z-index: 10;
	}
}

.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.modal-content {
	box-shadow: 0 0px 35px rgba(0,0,0,.1);
	border: none;
}

.logo-login {
	display: block;
	margin: 0 auto;
	width: 248px;
	height: 67px;
	background: url(../images/logo.svg);
	background-size: cover;
}
.logo-footer {
	display: block;
	margin: 15px auto;
	width: 80px;
	height: 21px;
	background: url(../images/logo.svg);
	background-size: cover;
}
.logo-header {
	display: block;
	margin: 0px auto;
	width: 131px;
	height: 37px;
	background: url(../images/logo.svg);
	background-size: cover;
}

.cart-icon {
	display: inline-block;
	vertical-align: middle;
	margin-top: -3px;
	margin-left: 15px;
	width: 22px;
	height: 20px;
	background: url(../images/cart.png);
}

.cart-quantity {
	display: inline-block;
	background: red;
	padding: 0 2px 0 0;
	margin: 0 -10px 0 0;
	width: 18px;
	height: 18px;
	text-align: center;
	line-height: 18px;
	border-radius: 100%;
	font-size: 11px;
	font-weight: 700;
	left: -9px;
	top: -9px;
}
