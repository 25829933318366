.suppliers-content .cart__total, .menu-item__info, .favorites .narrow, .order__total {
  *zoom: 1;
}

.suppliers-content .cart__total:before, .menu-item__info:before, .favorites .narrow:before, .order__total:before, .suppliers-content .cart__total:after, .menu-item__info:after, .favorites .narrow:after, .order__total:after {
  display: table;
  content: "";
  line-height: 0;
}

.suppliers-content .cart__total:after, .menu-item__info:after, .favorites .narrow:after, .order__total:after {
  clear: both;
}

/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}

body {
  margin-bottom: 200px;
  font-family: 'Open Sans', sans-serif;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 200px;
  color: #fff;
  text-align: center;
  font-size: 12px;
}

.footer p {
  margin: 18px 0 10px;
}

.footer .container {
  background-color: #3d9ece;
  padding-bottom: 15px;
  max-width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.footer .container.narrow {
  background: #fff;
  color: #a2a2a2;
  text-align: left;
  font-size: 15px;
  border-top: solid 1px #e5e5e5;
  padding: 26px 0;
  top: 0;
  bottom: auto;
}

.footer .container.narrow .phone {
  font-size: 16px;
  color: #000;
  font-weight: bold;
}

.container {
  width: 100% !important;
  max-width: 1400px;
}

.container.narrow {
  max-width: 900px;
}

h1 {
  font-weight: bold;
  font-size: 26px;
  margin: 10px 0 30px;
}

h1 .btn {
  float: right;
}

.btn {
  border: none;
  border-radius: 32px;
  padding-left: 25px;
  padding-right: 25px;
  transition: all .2s ease-in-out;
}

.btn:hover {
  transform: scale(1.06);
  transition: all .2s ease-in-out;
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.2);
}

.btn-success {
  background: #54ae53;
}

.btn-success:hover {
  background: #62d135;
}

.btn-info {
  background: #4ca9d4;
}

.btn-info:hover {
  background: #40aef8;
}

.header {
  background: #4ca9d4;
}

.header .navbar {
  min-height: 90px;
  color: #fff;
}

.header .navbar-header {
  height: 90px;
}

.header .navbar-collapse {
  padding-left: 20px;
  padding-right: 20px;
}

.header .navbar-default {
  background-color: transparent;
}

.header .navbar-brand {
  padding: 0 15px;
  margin-top: 23px;
  height: auto;
}

.header .navbar-toggle {
  top: 20px;
}

.header .navbar-default .navbar-toggle .icon-bar {
  background: #fff;
}

.header .navbar-nav {
  margin-top: 25px;
}

@media only screen and (max-width: 767px) {
  .header .navbar-nav {
    margin-bottom: 25px;
  }
}

.header .your-day-limit {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding-left: 20px;
  padding-right: 20px;
}

.header .navbar-default .navbar-nav > li {
  height: 40px;
  line-height: 40px;
}

.header .navbar-default .navbar-nav > li.exit {
  padding-left: 15px;
}

.header .navbar-default .navbar-nav > li.exit a {
  line-height: 1em;
  height: auto;
  display: inline-block;
  margin: 0 0 0 6px;
  padding: 0;
}

.header .navbar-default .navbar-nav > li > a {
  color: #fff;
  padding: 0;
  height: 40px;
  line-height: 40px;
  border-radius: 40px;
  padding: 0 20px;
  font-size: 14px;
}

.header .navbar-default .navbar-nav > li > a:hover {
  text-decoration: underline;
}

.header .navbar-default .navbar-nav > li > a .fa {
  margin-right: 10px;
  font-size: 16px;
}

.header .navbar-default .navbar-nav > .active > a,
.header .navbar-default .navbar-nav > .active > a:focus,
.header .navbar-default .navbar-nav > .active > a:hover {
  background: #fff;
  color: #4ca9d4;
  text-decoration: none;
}

.suppliers .nav-tabs {
  border: none;
  display: flex;
  align-items: flex-start;
  text-align: center;
  justify-content: space-between;
}

.suppliers .nav-tabs > li {
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
  float: none;
}

.suppliers .nav-tabs > li:first-child {
  padding-left: 0;
  border-left: none;
}

.suppliers .nav-tabs > li > a {
  padding: 0;
  font-size: 16px;
  font-weight: 500;
  color: #c1c1c1;
  border: none;
}

.suppliers .nav-tabs > li > a:hover {
  color: #3b3b3b;
  background: none;
}

.suppliers .nav-tabs > li.active > a, .suppliers .nav-tabs > li.active > a:focus, .suppliers .nav-tabs > li.active > a:hover {
  border: none;
  color: #3b3b3b;
}

.suppliers-content .container {
  position: relative;
  padding-top: 20px;
  padding-bottom: 30px;
  overflow: hidden;
}

@media only screen and (max-width: 768px) {
  .suppliers-content .container {
    padding-right: 0;
  }
}

.suppliers-content .tab-content {
  width: 75%;
}

@media only screen and (max-width: 992px) {
  .suppliers-content .tab-content {
    width: 60%;
  }
}

@media only screen and (max-width: 768px) {
  .suppliers-content .tab-content {
    width: 100%;
  }
}

.suppliers-content .cart {
  /*
position: absolute;
			right: 15px;
			top: 20px;
*/
  float: right;
  width: 25%;
  padding: 20px;
  border-radius: 20px;
  border: solid 1px #f2f2f2;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

@media only screen and (max-width: 992px) {
  .suppliers-content .cart {
    width: 40%;
  }
}

@media only screen and (max-width: 768px) {
  .suppliers-content .cart {
    width: auto;
    margin-right: 15px;
    float: none;
    margin-bottom: 20px;
  }
}

.suppliers-content .cart h4 {
  text-align: center;
  margin: 10px  0 20px;
  font-weight: bold;
  font-size: 25px;
}

.suppliers-content .cart__item, .suppliers-content .cart__title {
  display: flex;
  border-bottom: dashed 1px #e6e6e6;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 16px 0;
}

.suppliers-content .cart__item div, .suppliers-content .cart__title div {
  width: 20%;
  text-align: center;
}

.suppliers-content .cart__item div:first-child, .suppliers-content .cart__title div:first-child {
  width: 60%;
  text-align: left;
  padding-right: 15px;
}

.suppliers-content .cart__item div:last-child, .suppliers-content .cart__title div:last-child {
  width: 20%;
  text-align: right;
}

.suppliers-content .cart__item div:last-child span, .suppliers-content .cart__title div:last-child span {
  display: block;
}

.suppliers-content .cart__title {
  font-weight: 700;
  color: #cbcbcb;
}

.suppliers-content .cart__delete {
  display: inline-block;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 12px;
  font-size: 16px;
  border: solid 2px #a72523;
  color: #a72523;
  border-radius: 100%;
  text-decoration: none !important;
}

.suppliers-content .cart__delete:hover {
  color: #ed3833;
  border-color: #ed3833;
}

.suppliers-content .cart__total {
  font-weight: bold;
  padding: 20px 0 14px;
  margin-top: -1px;
  border-top: solid 1px #e6e6e6;
  border-bottom: solid 1px #e6e6e6;
  margin-bottom: 20px;
}

.suppliers-content .cart__total span {
  float: right;
  font-size: 20px;
  font-weight: normal;
  margin-top: -5px;
}

.suppliers-content .cart__limit {
  padding: 5px 0;
  margin: -20px 0 20px;
  color: #767676;
  font-size: 14px;
}

.suppliers-content .cart__limit-reached {
  background: #b95252;
  margin: 20px -20px -20px;
  padding: 10px;
  text-align: center;
  color: #fff;
}

.suppliers-content .cart__button {
  padding: 0 14%;
}

.suppliers-content .cart__button .btn[disabled] {
  background: #000;
  transform: none;
}

.suppliers .restaurants {
  background: #edf6fb;
  padding: 30px 0px;
  margin-top: -20px;
  margin-bottom: 20px;
  text-align: center;
}

.suppliers .restaurants__nav {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 16px;
  font-weight: 500;
}

.suppliers .restaurants__nav li {
  display: inline-block;
  vertical-align: top;
  border-right: solid 1px #d4dde0;
  padding: 0 30px;
  text-align: center;
}

.suppliers .restaurants__nav li:first-child {
  padding-left: 0;
}

.suppliers .restaurants__nav li:last-child {
  border: none;
  padding-right: 0;
}

.suppliers .restaurants__nav li.active a {
  color: #4ca9d4;
  text-decoration: underline;
}

.suppliers .restaurants__nav a {
  color: #b3b9bd;
}

.suppliers .restaurants__nav a:hover {
  color: #4ca9d4;
  text-decoration: none;
}

.menu-item {
  display: inline-block;
  vertical-align: top;
  width: 33%;
}

@media only screen and (max-width: 1200px) {
  .menu-item {
    width: 49%;
  }
}

@media only screen and (max-width: 992px) {
  .menu-item {
    width: 100%;
  }
}

.menu-item__content {
  margin: 0 30px 30px 0;
  border: solid 1px #f2f2f2;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 20px;
  position: relative;
}

@media only screen and (max-width: 992px) {
  .menu-item__content {
    margin-right: 15px;
  }
}

.menu-item__content h4 {
  font-size: 20px;
  margin-top: 0;
  min-height: 44px;
}

.menu-item__hidden {
  overflow: hidden;
  max-height: 53px;
  min-height: 53px;
  padding-bottom: 20px;
  position: relative;
  transition: all .3s ease-in-out;
  font-size: 12px;
  color: #9c9c9c;
  font-style: italic;
  margin-bottom: 10px;
}

.menu-item__hidden ul {
  padding-left: 15px;
}

.menu-item__hidden.open {
  max-height: 2000px;
  transition: all .3s ease-in-out;
}

.menu-item__hidden .hidden-show {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  text-align: right;
}

.menu-item__hidden .hidden-show:before {
  content: "...";
  position: absolute;
  left: 0;
  top: -3px;
  color: #9c9c9c;
}

.menu-item__hidden .hidden-show .glyphicon {
  font-size: 16px;
  color: #d7d7d7;
}

.menu-item__hidden .hidden-show .glyphicon:hover {
  color: #000;
}

.menu-item__hidden .hidden-show.open:before {
  display: none;
}

.menu-item__hidden .hidden-show.open .glyphicon {
  transform: rotate(180deg);
}

.menu-item__info {
  font-size: 16px;
  padding-top: 20px;
  border-top: dashed 1px #e5e5e5;
}

.menu-item__info span {
  float: left;
  padding-right: 15px;
  height: 32px;
  line-height: 32px;
  color: #9c9c9c;
}

.menu-item__info span.weight {
  color: #f1823b;
}

.menu-item__info .btn {
  float: right;
}

.panel-default {
  padding: 10px;
  border: solid 1px #f2f2f2;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

.panel-default h4 {
  font-weight: bold;
  margin: 0 0 20px;
}

.form-control {
  border-radius: 34px;
}

.favorites__title {
  float: left;
  font-weight: bold;
  margin: 5px 0 0 !important;
  font-size: 16px;
  color: #000 !important;
}

.favorites__days {
  float: right;
  font-size: 16px;
  font-weight: bold;
  color: #000;
  padding-top: 5px;
}

.favorites__days span {
  display: inline-block;
  font-size: 14px;
  color: #000;
  width: 30px;
  height: 30px;
  border: solid 2px transparent;
  border-radius: 30px;
  line-height: 27px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
}

.favorites__days span.active {
  color: #4ca9d4 !important;
  border-color: #4ca9d4;
}

.favorites__days.select-days span {
  color: #bcc3c7;
  cursor: pointer;
}

.favorites__days.select-days span:hover {
  color: #000;
}

.favorites__days .btn-group {
  margin-top: -2px;
  padding-left: 5px;
}

.favorites__days .btn-group .btn {
  font-size: 13px;
  padding: 0 !important;
  margin: 0 2px;
  background: none;
  box-shadow: none;
  transform: none;
  color: #000;
  width: 30px;
  height: 30px;
  border: solid 2px transparent;
  border-radius: 30px !important;
  line-height: 27px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  color: #bcc3c7;
  cursor: pointer;
  outline: none;
}

.favorites__days .btn-group .btn:hover {
  color: #000;
}

.favorites__days .btn-group .btn.active {
  color: #4ca9d4 !important;
  border: solid 2px #4ca9d4;
}

.favorites .menu-name {
  float: left;
  width: 300px;
}

.favorites__item, .favorites__titles {
  display: flex;
  border-bottom: dashed 1px #e6e6e6;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 0;
  width: 100%;
}

.favorites__item div, .favorites__titles div {
  width: 20%;
  text-align: center;
}

.favorites__item div:first-child, .favorites__titles div:first-child {
  width: 60%;
  text-align: left;
  padding-right: 15px;
}

.favorites__item div:last-child, .favorites__titles div:last-child {
  width: 20%;
  text-align: right;
}

.favorites__item div:last-child span, .favorites__titles div:last-child span {
  display: block;
}

.favorites__titles {
  font-weight: 700;
  color: #cbcbcb !important;
}

.favorites__buttons {
  padding: 30px 0;
  text-align: right;
}

.grid-view .summary {
  text-align: right;
  margin: -50px 0 30px;
}

.table > tbody > tr > td, c, .table > tfoot > tr > td, .table > tfoot > tr > th, .table > thead > tr > td, .table > thead > tr > th {
  vertical-align: middle;
  padding: 12px 8px;
  font-size: 15px;
}

.table > tbody > tr > td small, c small, .table > tfoot > tr > td small, .table > tfoot > tr > th small, .table > thead > tr > td small, .table > thead > tr > th small {
  display: block;
  color: #858585;
  padding-top: 5px;
  padding-bottom: 5px;
}

.table > thead > tr > th {
  color: #cbcbcb !important;
  border-bottom: solid 1px #e5e5e5 !important;
}

.table > tbody > tr > td {
  border-top: dashed 1px #e6e6e6;
}

.menu-table > tbody > tr > td {
  border-top: none;
  border-bottom: dashed 1px #e6e6e6;
}

.menu-table .price {
  white-space: nowrap;
  padding: 0 40px;
}

.login {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #4ca9d4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login__box {
  width: 310px;
}

.login h3 {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  margin: 30px 0 15px;
}

.login input {
  height: 40px;
  margin-bottom: 15px;
}

.login .btn {
  height: 47px;
  border: solid 1px #fff;
  background: transparent !important;
  transform: none !important;
  box-shadow: none !important;
  width: 200px;
  display: block;
  margin: 30px auto;
  font-size: 18px;
}

.order__item, .order__titles {
  display: flex;
  border-bottom: dashed 1px #e6e6e6;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 16px 0;
  width: 100%;
}

.order__item div, .order__titles div {
  width: 20%;
  text-align: center;
}

.order__item div:first-child, .order__titles div:first-child {
  width: 60%;
  text-align: left;
  padding-right: 15px;
}

.order__item div:last-child, .order__titles div:last-child {
  width: 20%;
  text-align: right;
}

.order__item div:last-child span, .order__titles div:last-child span {
  display: block;
}

.order__titles {
  font-weight: 700;
  color: #cbcbcb !important;
  border-top: dashed 1px #e6e6e6;
  border-bottom: none;
}

.order .alert {
  margin: 0;
}

.order__total {
  font-weight: bold;
  padding: 20px 0;
  font-size: 16px;
  border-top: solid 1px #e5e5e5;
  border-bottom: solid 1px #e5e5e5;
}

.order__total span {
  float: right;
  font-size: 20px;
  font-weight: normal;
  margin-top: -5px;
}

.order__buttons {
  text-align: right;
  padding: 20px 0 50px;
}

.order .order__list .order__item:last-child {
  border: none;
}

.steps {
  text-align: right;
  margin: -55px 0 30px;
}

.steps span {
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #dbeef6;
  margin: 0 3px;
}

.steps span.active {
  background: #4caad5;
  width: 30px;
  height: 30px;
  color: #fff;
  line-height: 30px;
  text-align: center;
  font-weight: bold;
}

.calendar-box {
  border: solid 1px red;
  width: 400px;
  border: solid 1px #f2f2f2;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 20px;
}

.calendar {
  width: 100%;
}

.calendar td, .calendar th {
  text-align: center;
  padding: 8px;
  text-transform: uppercase;
}

.calendar .month-year-row {
  border-bottom: solid 1px #d2d4d8;
}

.calendar .month-year-row th {
  padding-bottom: 20px;
}

.calendar .weekdays-row th {
  padding: 20px 0;
}

.calendar .btn {
  padding: 0;
  box-shadow: none !important;
  transform: none !important;
  text-decoration: none !important;
  font-weight: bold;
  color: #ccc;
  width: 30px;
  height: 30px;
  border: solid 2px transparent;
  border-radius: 100%;
  line-height: 27px;
  text-align: center;
}

.calendar .btn.available-date {
  color: #4ca9d4;
}

.calendar .btn.btn-success {
  background: none;
  color: #000;
  border-color: #4ca9d4;
}

.new-tabs {
  border: none;
  display: flex;
  align-items: flex-start;
  text-align: center;
  justify-content: space-between;
}

.new-tabs > li {
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
  float: none;
}

.new-tabs > li:first-child {
  padding-left: 0;
  border-left: none;
}

.new-tabs > li > a {
  padding: 0;
  font-size: 16px;
  font-weight: 500;
  color: #c1c1c1;
  border: none;
}

.new-tabs > li > a:hover {
  color: #3b3b3b;
  background: none;
}

.new-tabs > li.active > a, .new-tabs > li.active > a:focus, .new-tabs > li.active > a:hover {
  border: none;
  color: #3b3b3b;
}

.numbers-row {
  width: 100px;
  padding: 0 25px;
  position: relative;
  display: inline-block;
}

.numbers-row input {
  width: 100%;
  text-align: center;
  height: 35px;
  border: solid 1px #cfcfcf;
}

.numbers-row .inc {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  width: 25px;
  border: solid 1px #cfcfcf;
  border-left: none;
  cursor: pointer;
  border-radius: 0px 18px 18px 0;
  text-align: center;
  line-height: 32px;
  font-size: 16px;
  background: #fff;
}

.numbers-row .inc:hover {
  background: #54ae53;
  color: #fff;
}

.numbers-row .dec {
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  width: 25px;
  border: solid 1px #cfcfcf;
  border-right: none;
  cursor: pointer;
  border-radius: 18px 0 0 18px;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  background: #fff;
}

.numbers-row .dec:hover {
  background: #b83131;
  color: #fff;
}

.meal {
  color: #54ae53;
  font-style: italic;
  display: block !important;
  font-size: 12px;
}

.new-item {
  position: relative;
}

.new-item.selected {
  background: #eef7ed;
}

.new-item.selected .fa {
  display: block;
}

.new-item .fa {
  position: absolute;
  left: -40px;
  top: 50%;
  margin-top: -16px;
  font-size: 30px;
  font-weight: normal;
  color: #54af53;
  display: none;
}

.new-item .container {
  display: flex;
  align-items: center;
  border-bottom: dashed 1px #e6e6e6;
  max-width: 870px;
  padding: 20px 0;
  position: relative;
}

.new-item__desc {
  width: 70%;
  padding-right: 60px;
  color: #858585;
  font-size: 12px;
}

.new-item__desc h4 {
  margin: 0 0 4px;
  font-size: 16px;
  color: #000;
}

.new-item__price {
  width: 10%;
}

.new-item__add {
  width: 20%;
  text-align: right;
}

.modal {
  text-align: center;
  padding: 0 !important;
  background: rgba(76, 169, 212, 0.8);
}

.modal .close {
  position: absolute;
  right: 8px;
  top: 5px;
  z-index: 10;
}

.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.modal-content {
  box-shadow: 0 0px 35px rgba(0, 0, 0, 0.1);
  border: none;
}

.logo-login {
  display: block;
  margin: 0 auto;
  width: 248px;
  height: 67px;
  background: url(../images/logo.svg);
  background-size: cover;
}

.logo-footer {
  display: block;
  margin: 15px auto;
  width: 80px;
  height: 21px;
  background: url(../images/logo.svg);
  background-size: cover;
}

.logo-header {
  display: block;
  margin: 0px auto;
  width: 131px;
  height: 37px;
  background: url(../images/logo.svg);
  background-size: cover;
}

.cart-icon {
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
  margin-left: 15px;
  width: 22px;
  height: 20px;
  background: url(../images/cart.png);
}

.cart-quantity {
  display: inline-block;
  background: red;
  padding: 0 2px 0 0;
  margin: 0 -10px 0 0;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  border-radius: 100%;
  font-size: 11px;
  font-weight: 700;
  left: -9px;
  top: -9px;
}
